import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function HoverCard({ children, className, style, aos, aosDelay, aosOffset }) {
  const cardRef = useRef(null);

  const handleMouseMove = (e) => {
    const card = cardRef.current;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left; // X position within the card
    const y = e.clientY - rect.top; // Y position within the card

    // Calculate offset from center (-1 to 1)
    const offsetX = (x / rect.width - 0.5) * 3;
    const offsetY = (y / rect.height - 0.5) * 3;

    card.style.transform = `translate(${offsetX * 10}px, ${offsetY * 10}px)`;
  };

  const handleMouseLeave = () => {
    cardRef.current.style.transform = 'translate(0px, 0px)';
  };

  return (
    <div
      ref={cardRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      className="hover-card"
    >
      <div
        data-aos={aos}
        data-aos-delay={aosDelay}
        data-aos-offset={aosOffset}
        className={className}
        style={{
          // transition: 'transform 0.1s ease',
          willChange: 'transform',
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
}

HoverCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  aos: PropTypes.string,
  aosDelay: PropTypes.number,
  aosOffset: PropTypes.number,
};

HoverCard.defaultProps = {
  className: null,
  style: null,
  aos: null,
  aosDelay: null,
  aosOffset: null,
};

export default HoverCard;
