import PropTypes from 'prop-types';

const Button = ({ onClick, primary, children, label }) => {
  return (
    <button
      onClick={onClick || null}
      aria-label={label}
      className={`btn click ${primary && 'primcolor-bg'}`}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

Button.defaultProps = {
  onClick: null,
  primary: false,
  label: null,
};

export default Button;
