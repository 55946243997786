import React from 'react';
import Package from '../UI/Packages/Package';

function PackagesSection() {
  const packages = [
    {
      name: 'STARTER',
      pricePerPage: 150,
      items: [
        'Up to 5 pages',
        'Responsive design',
        'Basic SEO optimization',
        '3 revisions',
      ],
    },
    {
      name: 'PROFESSIONAL',
      pricePerPage: 200,
      items: [
        '+ Everything from the previous package',
        'Up to 10 pages',
        'Analytics',
        '5 revisions',
      ],
    },
    {
      name: 'ENTERPRISE',
      pricePerPage: 300,
      items: [
        '+ Everything from the previous package',
        'Up to 20 pages',
        'Custom Animations',
        'Enhanced SEO strategies',
        'Backend - Firebase',
        'E-commerce functionality',
        '10 revisions',
        'Logo design',
      ],
    },
    {
      name: 'CUSTOM',
      pricePerPage: '?',
      items: [
        'Unlimited pages',
        'Expert level SEO strategies',
        'Fully custom website',
        'Custom Backend',
        'Branding design',
      ],
      para: 'Our experts ensure it reflects your brand, captivates audiences, and achieves your goals.',
    },
  ];

  return (
    <section id="packages">
      <div className="container">
        <div className="row">
          <h1 className="packages--title title">Transparent Pricing Plans</h1>
          <div className="section-line"></div>
          <h2 className="packages--sub-title sub-title">
            Choose a plan that fits your needs and budget. Start your journey
            with AG Web Studios today.
          </h2>
          <div className="packages--container">
            {packages.map((pack) => (
              <Package key={pack.name} pack={pack}></Package>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PackagesSection;
