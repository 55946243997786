import { useEffect, useState } from 'react';
import Button from '../Button';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import PropTypes from 'prop-types';
import Skeleton from '../Skeleton';

function Testimonial({ testimonials }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imgSrc, setImgSrc] = useState('');

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  useEffect(() => {
    setImgSrc('');

    const image = new Image();
    image.src = testimonials[currentIndex].pfp;

    image.onload = () => {
      setImgSrc(testimonials[currentIndex].pfp);
    };

    return () => {
      image.src = '';
    };
  }, [testimonials, currentIndex]);

  if (!testimonials.length) return null;

  return (
    <div className="testimonial">
      <div className="testimonial__pfp--container">
        {imgSrc ? (
          <img src={imgSrc} alt="" className="testimonial__pfp" />
        ) : (
          <Skeleton width="100%" height="100%" />
        )}
      </div>
      <div className="testimonial--right">
        <p className="testimonial__para para">
          &quot;{testimonials[currentIndex].text}&quot;
        </p>
        <h2 className="testimonial__name">{testimonials[currentIndex].name}</h2>
        <p className="testimonial__job">{testimonials[currentIndex].job}</p>
        <div className="testimonial__btns">
          <div className="testimonial_prev">
            <Button primary onClick={prev} label="Previous Testimonial">
              <FaArrowLeft />
              <span className="testimonial__btn-text">Previous</span>
            </Button>
          </div>
          <div className="testimonial_next">
            <Button primary onClick={next} label="Next Testimonial">
              <span className="testimonial__btn-text">Next</span>
              <FaArrowRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

Testimonial.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      job: PropTypes.string.isRequired,
      pfp: PropTypes.string.isRequired,
    })
  ),
};

export default Testimonial;
