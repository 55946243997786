import React from 'react';
import Testimonial from '../UI/Home/Testimonial';
import vertigoesPFP from '../../assets/PFPs/VertigoesPFP.jpg';
import IngridPFP from '../../assets/PFPs/IngridPFP.jpg';

const testimonials = [
  {
    text: 'AG Web Studios has captured our visual musical expression. AG Web has managed to integrate music videos and images in the best possible way. We have been very satisfied with the collaboration.',
    name: 'The Vertigoes',
    job: 'Indie Band',
    pfp: vertigoesPFP,
  },
  {
    text: "I couldn't be happier with the service I received from AG web Studios. They have been helping me with my homepage and webshop. Quick, efficient, and incredibly insightful. They found smart solutions to my IT challenges and made everything seem easy. Highly recommended!!",
    name: 'Ingrid Riis',
    job: 'Freelance Photographer',
    pfp: IngridPFP,
  },
];

function Testimonials() {
  return (
    <section id="testimonials">
      <div className="container">
        <div className="row start">
          <div
            className="start col"
            data-aos="fade-down"
            data-aos-delay="0"
            data-aos-offset="-20"
          >
            <span className="small-text">Customer Feedback</span>
            <h1 className="testimonials__title title">They Love Us.</h1>
            <p className="title-para">
              We take pride in our <b>clients&apos; satisfaction</b> and
              long-term relationships.
            </p>
          </div>
          <div className="testimonials--container">
            <Testimonial testimonials={testimonials} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
