import HoverCard from '../UI/HoverCard';

const WorkingSteps = () => {
  return (
    <section id="workingsteps">
      <div className="row">
        <div className="left col start background-grid">
          <div className="small-text" data-aos="fade-down" data-aos-offset="20">
            Working Steps
          </div>
          <h1 className="title" data-aos="fade-down" data-aos-offset="-20">
            Our Process
          </h1>
          <div className="working__steps--container">
            <HoverCard
              className="step--card background-grid"
              aos="fade-right"
              aosOffset="-50"
            >
              <div className="step__number-container">
                <span>Step</span>
                <span>01</span>
              </div>
              <h1 className="step--title">Initial Consultation</h1>
            </HoverCard>
            <HoverCard className="step--card" aos="fade-left" aosOffset="-50">
              <div className="step__number-container">
                <span>Step</span>
                <span>02</span>
              </div>
              <h1 className="step--title">Strategy & Planning</h1>
            </HoverCard>
            <HoverCard
              className="step--card background-dots-full"
              aos="fade-up"
              aosOffset="-50"
            >
              <div className="step__number-container">
                <span>Step</span>
                <span>03</span>
              </div>
              <h1 className="step--title">Execution & Launch</h1>
            </HoverCard>
          </div>
        </div>
        <div className="right">
          <div className="quote-svg">
            <svg
              width="136"
              height="152"
              viewBox="0 0 136 152"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M99.6484 20.2667C78.5409 20.2667 63.3017 37.5566 63.3017 63.1602C63.4339 100.356 91.6191 126.655 132.281 131.703C136.048 132.175 137.502 126.982 134.032 125.455C118.436 118.57 110.552 109.837 109.535 101.195C108.775 94.7369 112.31 89.0785 116.758 88.0164C128.276 85.2691 135.995 71.0347 135.995 56.3282C135.995 46.7641 132.166 37.5917 125.349 30.8289C118.533 24.066 109.288 20.2667 99.6484 20.2667ZM20.3466 20.2667C-0.760849 20.2667 -16 37.5566 -16 63.1602C-15.8678 100.356 12.3173 126.655 52.9793 131.703C56.7461 132.175 58.2 126.982 54.7305 125.455C39.1345 118.57 31.2506 109.837 30.2329 101.195C29.4729 94.7369 33.0085 89.0785 37.456 88.0164C48.9746 85.2691 56.6933 71.0347 56.6933 56.3282C56.6933 46.7641 52.8639 37.5917 46.0476 30.8289C39.2313 24.066 29.9864 20.2667 20.3466 20.2667Z" />
              </g>
            </svg>
          </div>

          <h2 className="small-title">
            <div className="working__steps-line"></div>
            We specialize in creating a strong digital presence for modern
            businesses.
          </h2>

          <h3 className="working__founder">Aksel Glyholt</h3>
          <h4 className="sub-title">CEO & Founder</h4>
        </div>
      </div>
    </section>
  );
};

export default WorkingSteps;
