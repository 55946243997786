import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const CookiesContext = createContext({}); // Provide a more realistic default value

export const CookiesProvider = ({ children }) => {
  const [consent, setConsent] = useState({
    essential: true,
    analytics: true,
    marketing: false,
  });
  const [editPref, setEditPref] = useState(false);
  const [showCookiesBanner, setShowCookiesBanner] = useState(false);

  const setAnalyticsCookies = useCallback(() => {
    // Check if the script is already added to avoid duplicates
    if (document.querySelector('script[src*="googletagmanager"]')) return;

    // Create the script element for Google Tag Manager (Consent Mode script)
    const consentScript = document.createElement('script');
    consentScript.src =
      'https://www.googletagmanager.com/gtag/js?id=G-M09L2Z4ZVB'; // Update with actual tracking ID
    consentScript.async = true;
    document.head.appendChild(consentScript);

    // Initialize Google Analytics with Consent Mode
    consentScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      // Set the default consent state
      gtag('consent', 'default', {
        ad_storage: 'denied', // Initially deny ad storage
        ad_user_data: 'denied', // Deny until we add ad consent
        ad_personalization: 'denied', // Deny until we add ad consent
        analytics_storage: consent.analytics ? 'granted' : 'denied', // If analytics is accepted, grant consent
      });

      // After consent, configure Google Analytics
      gtag('js', new Date());
      gtag('config', 'G-M09L2Z4ZVB', { anonymize_ip: true }); // Use the actual tracking ID
    };
  }, [consent.analytics]);

  const setMarketingCookies = () => {
    // Example: Load Facebook Pixel or other marketing scripts
  };

  useEffect(() => {
    // Check for saved consent
    const savedConsent = localStorage.getItem('cookieConsent');
    if (savedConsent) {
      const jsonedConsent = JSON.parse(savedConsent);
      setConsent(jsonedConsent);
      if (jsonedConsent.analytics) setAnalyticsCookies();
      if (jsonedConsent.marketing) setMarketingCookies();
    } else {
      setShowCookiesBanner(true);
    }
  }, [setAnalyticsCookies]);

  const saveConsentToLocalStorage = (newConsent) => {
    localStorage.setItem('cookieConsent', JSON.stringify(newConsent));
    setConsent(newConsent);
  };

  // Function to handle the accept cookies button
  const handleAccept = useCallback(() => {
    saveConsentToLocalStorage(consent);

    if (consent.analytics) setAnalyticsCookies();
    if (consent.marketing) setMarketingCookies();

    setShowCookiesBanner(false);
    setEditPref(false);

    // Refresh the window if any consent category is false
    if (!consent.analytics || !consent.marketing) {
      window.location.reload();
    }
  }, [consent, setAnalyticsCookies]);

  // Function to handle the accept all cookies button
  const handleAcceptAll = useCallback(() => {
    const newConsent = {
      essential: true,
      analytics: true,
      marketing: true,
    };

    saveConsentToLocalStorage(newConsent);

    setAnalyticsCookies();
    setMarketingCookies();

    setShowCookiesBanner(false);
  }, [setAnalyticsCookies]);

  // Function to handle the reject cookies button
  const handleReject = useCallback(() => {
    const newConsent = {
      essential: true,
      analytics: false,
      marketing: false,
    };

    saveConsentToLocalStorage(newConsent);
    deleteCookies(['analytics', 'marketing']);

    setShowCookiesBanner(false);
    setEditPref(false);
  }, []);

  // Function to handle the consent change
  const handleConsentChange = useCallback(
    (category, value) => {
      console.log(category, value);
      const newConsent = {
        ...consent,
        [category]: value,
      };

      saveConsentToLocalStorage(newConsent);
    },
    [consent]
  );

  const deleteCookies = (categories = []) => {
    const cookiesToDelete = {
      analytics: ['_ga', '_gid'], // Add real cookie names
      marketing: ['_fbp'], // Add real cookie names
    };

    categories.forEach((category) => {
      const cookies = cookiesToDelete[category] || [];
      cookies.forEach((cookieName) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
    });
  };

  // Memoized value to prevent unnecessary re-renders
  const memoedValue = useMemo(
    () => ({
      editPref,
      setEditPref,
      showCookiesBanner,
      handleAccept,
      handleAcceptAll,
      handleReject,
      handleConsentChange,
      consent,
    }),
    [
      editPref,
      showCookiesBanner,
      handleAccept,
      handleAcceptAll,
      handleReject,
      handleConsentChange,
      consent,
    ]
  );

  return (
    <CookiesContext.Provider value={memoedValue}>
      {children}
    </CookiesContext.Provider>
  );
};

CookiesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useCookies() {
  const context = useContext(CookiesContext);
  if (!context) {
    throw new Error('useCookies must be used within a CookiesProvider');
  }
  return context;
}
