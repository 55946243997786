import PropTypes from 'prop-types';
import ArrowLink from '../../UI/ArrowLink';
import { Link } from 'react-router-dom';

const Service = ({ Icon, title, text, points }) => {
  return (
    <div
      className="service"
      data-aos="fade-right"
      data-aos-offset="0"
      data-aos-duration="1000"
    >
      <Link to={'/services'} className="service__icon-container">
        <Icon />
        <h1 className="service--title">{title}</h1>
      </Link>
      <div className="service--middle">
        <p className="service--text">{text}</p>
        <ul className="service__points">
          {points.map((point, index) => (
            <li className="service--point" key={index}>
              + {point}
            </li>
          ))}
        </ul>
      </div>
      <ArrowLink destination="/services" text="View Details"></ArrowLink>
    </div>
  );
};

Service.propTypes = {
  Icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Service.defaultProps = {
  Icon: null,
  title: null,
  text: null,
  points: [],
};

export default Service;
