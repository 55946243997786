import '../style/About/AboutPage.scss';
import '../style/About/Breakpoints.scss';
import useDocumentTitle from '../lib/useDocumentTitle';
import Founder from '../components/About/Founder';
import OurStory from '../components/About/OurStory';
import OurMission from '../components/About/OurMission';

function About() {
  useDocumentTitle('About');

  return (
    <main id="about-page">
      <header id="about-landing">
        <img
          src="https://images.unsplash.com/photo-1670487274509-f17845d560e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjAzNTV8MHwxfHJhbmRvbXx8fHx8fHx8fDE3Mjc0NTg2MTl8&ixlib=rb-4.0.3&q=100&w=1920"
          alt="Background"
          className="about__landing-img"
          loading="eager"
          fetchPriority="high"
        />
        <div className="container">
          <div className="row">
            <div data-aos="fade-right" className="white-line"></div>
            <h1 data-aos="fade-left" className="about--title title">
              Crafting Exceptional
              <br />
              Digital Experiences
            </h1>
            <div data-aos="fade-right" className="about__header-bottom">
              <p className="about__header-para">
                At AG Web Studios, we transform your ideas into compelling
                digital experiences that drive business success.
              </p>
              <div className="white-line"></div>
              <p className="about__header-para">
                Innovative Solutions, Proven Results - Our dedicated team of
                designers and developers work collaboratively to deliver
                innovative solutions that meet your business goals.
              </p>
            </div>
          </div>
        </div>
      </header>
      <Founder />
      <OurStory />
      <OurMission />
    </main>
  );
}

export default About;
