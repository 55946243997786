import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/AGWebLogo-Full.webp';
import { FaXTwitter } from 'react-icons/fa6';
import useCookies from '../hooks/useCookies';

function Footer() {
  const { setEditPref } = useCookies();

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="footer--section">
            <h1 className="footer--title">Website</h1>
            <ul className="footer--links">
              <li className="footer--link">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="footer--link">
                <Link to={'/about'}>About us</Link>
              </li>
              <li className="footer--link">
                <Link to={'/contact'}>Contact</Link>
              </li>
              <li className="footer--link">
                <Link to={'/pricing'}>Pricing</Link>
              </li>
            </ul>
          </div>
          <div className="footer--section">
            <h1 className="footer--title">Company</h1>
            <ul className="footer--links">
              <li className="footer--link">
                <Link to={'/services'}>Services</Link>
              </li>
              <li className="footer--link">
                <Link to={'/about'}>About us</Link>
              </li>
              <li className="footer--link">
                <Link to={'/contact'}>Contact</Link>
              </li>
            </ul>
          </div>
          <div className="footer--section">
            <h1 className="footer--title">Legal</h1>
            <ul className="footer--links">
              <li className="footer--link">
                <Link to={'/privacy-policy'}>Privacy Policy</Link>
              </li>
              <li className="footer--link">
                <Link to={'/cookie-policy'}>Cookie Policy</Link>
              </li>
              <li className="footer--link">
                <button onClick={() => setEditPref(true)}>
                  Cookie Preferences
                </button>
              </li>
            </ul>
          </div>
          <div className="footer--section">
            <figure className="footer__img--wrapper">
              <img src={logo} alt="" className="footer__img" />
            </figure>
            <div className="footer--info">
              <div className="footer__info--top">
                <a href="tel:+4523819339">+45 23 81 93 39</a>
                <span className="splitter"></span>
                <a href="mailto:contact@agweb.studio">contact@agweb.studio</a>
              </div>
              <span className="footer__cvr">CVR: 45281043</span>
            </div>
            <a
              href="https://www.x.com/agwebstudios"
              target="_blank"
              rel="noreferrer noopener"
              className="footer__social"
              aria-label="X (Twitter)"
            >
              <FaXTwitter />
            </a>
            <div className="footer__copyright--container">
              <Link
                to={'/privacy-policy'}
                className="footer__privacy--policy--link"
              >
                Privacy Policy
              </Link>
              <p className="footer__copyright--para">
                &#169; 2025 AG Web Studios. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
