import React from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import ArrowLink from '../UI/ArrowLink';
import PlanetOrbit from '../../assets/PlanetOrbit.png';
import HoverCard from '../UI/HoverCard';

function AboutSec() {
  return (
    <div id="aboutsec">
      <div className="container">
        <div className="row">
          <span
            className="small-text"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            About AG Web Studios
          </span>

          <div className="aboutsec-top">
            <div
              className="aboutsec__top-left"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <h1 className="title">
                We help brands grow in the digital world.{' '}
              </h1>
              <p className="title-para">
                From startups to established companies, we offer services that
                make a difference online. Serving{' '}
                <b>Businesses, Organizations, and Individuals</b> globally.
              </p>
            </div>
            <div className="aboutsec__top-right"></div>
          </div>

          <div className="aboutsec__middle">
            <HoverCard
              aos="fade-down"
              aosDelay="100"
              aosOffset="10"
              className="aboutsec__card card-1"
            >
              <h1 className="aboutsec__card-title title">05</h1>
              <p className="aboutsec__card-sub-title">World-Wide Branches</p>
            </HoverCard>
            <HoverCard
              aos="fade-down"
              aosDelay="200"
              className="aboutsec__card card-2"
            >
              <h1 className="aboutsec__card-title title">99%</h1>
              <p className="aboutsec__card-sub-title">
                Customer Retention Rate
              </p>
            </HoverCard>
            <HoverCard
              aos="fade-down"
              aosDelay="300"
              className="aboutsec__card card-3"
              style={{
                backgroundImage: `url(${PlanetOrbit})`,
              }}
            >
              <h1 className="aboutsec__card-title">Keep Reading</h1>
              <Link to={'/about'} className="aboutsec__card-sub-title">
                About AG Web Studios <FaArrowRight />
              </Link>
            </HoverCard>
          </div>

          <div
            className="info-box"
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-offset="0"
          >
            <h6>Focused on delivering results that exceed expectations.</h6>
            <ArrowLink
              text="Start The Journey"
              destination="/contact"
            ></ArrowLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSec;
