const FloatingBoatVector = () => {
  return (
    <svg
      className="boat-vector"
      width="50"
      height="48"
      viewBox="0 0 50 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25 48C38.8071 48 50 36.8071 50 23H0C0 36.8071 11.1929 48 25 48Z"></path>
      <path d="M26 0C35.9411 0 44 8.05888 44 18H26V0Z"></path>
    </svg>
  );
};

export default FloatingBoatVector;
