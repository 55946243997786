import Service from './UI/Service';
import { GiAtom } from 'react-icons/gi';
import { IoIosGlobe } from 'react-icons/io';
import { BsPencil } from 'react-icons/bs';
import ArrowLink from '../UI/ArrowLink';
import { CiServer } from 'react-icons/ci';

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <div className="row col start">
          <span
            className="small-text"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            Smart Solution
          </span>
          <h1 className="title" data-aos="fade-right" data-aos-delay="200">
            Scope of Work
          </h1>
          <p className="title-para" data-aos="fade-right" data-aos-delay="200">
            Our services are crafted to help clients thrive across{' '}
            <b>all digital touchpoints.</b>
          </p>
          <div className="services--container">
            <Service
              Icon={IoIosGlobe}
              title="Web Development & Design."
              text="Modern, high-performing websites built to impress and convert."
              points={['Web Development', 'UI/UX Design', 'Strategy & Consult']}
            />
            <Service
              Icon={CiServer}
              title="Backend Development"
              text="We build the systems behind the scenes that keep your website fast, secure, and running smoothly."
              points={[
                'API Development',
                'Database Architecture',
                'Server Management',
              ]}
            />
            <Service
              Icon={GiAtom}
              title="E-commerce Solutions"
              text="Online stores designed to drive sales and grow with you."
              points={['Store Design', 'Shopping Cart', 'Payment Gateway']}
            />
            <Service
              Icon={BsPencil}
              title="Branding & Graphic Design"
              text="Create a bold, consistent brand that truly stands out."
              points={[
                'Digital Strategy',
                'Competitor Analysis',
                'Market Research',
              ]}
            />
          </div>
          <div
            className="info-box"
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-offset="-50"
          >
            <h6>To Make Sure to Best Service Provide Our Clients.</h6>
            <ArrowLink
              text="Start The Journey"
              destination="/contact"
            ></ArrowLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
