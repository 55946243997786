import React, { memo, useEffect } from 'react';
import Scroll from '../UI/Scroll';
import { FaCode, FaHeadset } from 'react-icons/fa6';
import { IoTrendingUp } from 'react-icons/io5';
import FloatingBoatVector from '../UI/FloatingBoatVector';

import LandingImg from './UI/LandingImg';
import designImg from '../../assets/Design.jpeg';
import { FaSearch } from 'react-icons/fa';
import { IoMdColorPalette } from 'react-icons/io';
import ArrowLink from '../UI/ArrowLink';

const LANDING_IMAGES = [
  {
    first: true,
    img: designImg,
    title: 'Innovative Design',
    text: 'Cutting-edge web design',
    Icon: IoMdColorPalette,
  },
  {
    img: 'https://petrotechinc.com/wp-content/uploads/2019/06/iStock-1356364268.jpg',
    title: 'Robust Development',
    text: 'Reliable and scalable',
    Icon: FaCode,
  },
  {
    img: 'https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg',
    title: 'SEO Optimzation',
    text: 'Boost your visibility',
    Icon: FaSearch,
  },
  {
    img: 'https://www.ringcentral.com/content/dam/rc-www/en_us/images/content/seo/customer-support-team/Call-queue-hero-png-rendition.webp',
    title: '24/7 Support',
    text: 'Always here to help',
    Icon: FaHeadset,
  },
];

const preloadImages = (srcArray) => {
  srcArray.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

const Landing = memo(function Landing() {
  useEffect(() => {
    // Preload all images
    const imageSources = LANDING_IMAGES.map((item) => item.img);
    preloadImages(imageSources);
  }, []);

  return (
    <>
      <section id="landing">
        <div id="landing--container" className="background-dots">
          <div className="background-lines"></div>

          <div className="container">
            <div className="row">
              <div className="left landing--left">
                <span className="landing-top-span">
                  #1 Web Agency in Denmark
                </span>
                <h1 className="landing--title title">
                  Pioneering Digital Excellence.
                </h1>
                <h2 className="sub-title sub-title-landing">
                  We provide services in Online Marketing,{' '}
                  <b>Web Development, Design,</b> and other{' '}
                  <b>digital solutions</b> tailored to your needs.
                </h2>
                <div className="landing-bottom">
                  <div className="flex-row">
                    <ArrowLink
                      destination={'/contact'}
                      text="Let's Chat!"
                    ></ArrowLink>

                    <div className="landing-stat">
                      <div className="landing__stat-left">
                        <IoTrendingUp />
                      </div>
                      <div className="landing__stat-right">
                        <span className="landing_stat-number">2X+</span>
                        <span>Success Rate</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="landing__left-text-ball-container">
                  <span className="landing__left-border-ball"></span>
                  <span className="landing__left-border-ball-text">
                    Unique Depth, <br /> Bold Impact.
                  </span>
                </div>
                <FloatingBoatVector />
              </div>
              <div className="right landing--right">
                {LANDING_IMAGES.map((props, index) => (
                  <LandingImg key={index} {...props} />
                ))}
              </div>
            </div>
          </div>
          <Scroll anchor="aboutsec" />
        </div>
      </section>
    </>
  );
});

export default Landing;
